import React from "react";

import OnboardingTemplate from "../../templates/OnboardingTemplate";
import PlatformSelectionForm from "../../organisms/PlatformSelectionForm";

const Step1 = () => {
  return (
    <OnboardingTemplate leftCol={<PlatformSelectionForm />} rightCol={<></>} />
  );
};

export default Step1;
