import React, { useState } from "react";

import OnboardingTemplate from "../../templates/OnboardingTemplate";
import OnboardingStep from "../../templates/OnboardingStep";
import Guide from "../../templates/Guide";
import { Col, Form, Input, Row, Table } from "antd";
import Button from "../../atoms/Button";
import OnboardingTemplateFooter from "../../organisms/OnboardingTemplateFooter";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../../config/config.js";
import CustomerTag from "../../atoms/CustomerTag.js";
import Title from "antd/es/typography/Title.js";
import "../style.css";
import { useDispatch } from "react-redux";
import { addTag } from "../../../app/createdTagsSlice.js";
import EditableRow from "../../molecules/EditableRow.js";
import EditableCell from "../../molecules/EditableCell.js";
import { useAuth } from "../../../provider/authProvider";

const Step5 = () => {
  const user = useSelector((state) => state.user.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { backendAxios } = useAuth();
  const nextBtnHandler = () => {
    dispatch(addTag("NextPurchaseReminder"));
    if (user.email != config.testEmail) {
      const requests = [
        {
          tagName: "NextPurchaseReminder",
          dataField: "nextPurchaseReminderDate",
          days: 0,
          direction: "next",
        },
      ].map((criteria) =>
        backendAxios.post("tagsCriteria", {
          ...criteria,
          shop: user.store.shop,
        })
      );
      Promise.all(requests)
        .then(() => {
          console.log("All tags criteria have been posted successfully");
        })
        .catch((error) => {
          console.error("Error posting tags criteria:", error);
        });
    }
    navigate("/onboarding/step6");
  };
  const [dataSource, setDataSource] = useState([
    { key: "1", rebuyGap: "<30", howEarly: "3" },
    { key: "2", rebuyGap: "31-40", howEarly: "4" },
    { key: "3", rebuyGap: "41-50", howEarly: "5" },
    { key: "4", rebuyGap: "51-60", howEarly: "6" },
    { key: "5", rebuyGap: ">60", howEarly: "7" },
  ]);

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };
  const columns = [
    {
      title: "Rebuy Gap (days)",
      dataIndex: "rebuyGap",
      editable: false,
      width: 100,
    },
    {
      title: "How Early? (days)",
      dataIndex: "howEarly",
      editable: true,
      width: 100,
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <OnboardingTemplate
      showCreatedTags={true}
      leftCol={
        <OnboardingStep
          title="Use Case #3"
          percent={75}
          subtitle="Timely Replenishment Reminder"
        >
          <Guide
            guideData={{
              title: "",
              guideSpan: 24,
              guideSections: [
                {
                  text: "You will need to set how early you want to remind the customer before their expected purchase date. Replenishment reminders are a good automation to send SMS so you can use this to send either email or SMS. NextPurchaseReminder tag will be generated for all customers who needs to receive replenishment reminder that day. You can use this tag to setup automations for the reminder to be sent.",
                },
              ],
            }}
          />
        </OnboardingStep>
      }
      rightCol={
        <OnboardingStep
          title=""
          percent={null}
          subtitle="Define how you early you want to send reminder"
          footer={<OnboardingTemplateFooter onContinue={nextBtnHandler} />}
        >
          <Guide
            guideData={{
              title: "",
              guideSpan: 24,
              guideSections: [
                {
                  text: (
                    <Row>
                      <Col span={16} style={{ padding: "10px" }}>
                        <Table
                          components={{
                            body: {
                              row: EditableRow,
                              cell: EditableCell,
                            },
                          }}
                          dataSource={dataSource}
                          columns={mergedColumns}
                          pagination={false}
                          bordered={true}
                        />
                      </Col>
                    </Row>
                  ),
                },
                {
                  text: "",
                },
                {
                  text: (
                    <Row>
                      <Title level={5}>Tags Created:</Title>
                      <Col span={24}>
                        <Row>
                          <Col span={12} style={{ marginTop: "10px" }}>
                            <CustomerTag
                              type="default"
                              checked={true}
                              style={{ height: "50px" }}
                            >
                              NextPurchaseReminder
                            </CustomerTag>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ),
                },
              ],
            }}
          />
        </OnboardingStep>
      }
    />
  );
};

export default Step5;
