import React, { useEffect } from "react";
import LoggedInPagesTemplate from "../../templates/LoggedInPagesTemplate";
import RevenueCard from "../../molecules/Dashbaord/RevenueCard";
import OverviewChart from "../../organisms/Dashboard/OverviewChart";

import ToDoList from "../../organisms/Dashboard/ToDoList";
import TagsList from "../../organisms/Dashboard/TagsList";
import { Row, Col } from "antd";
import { useAuth } from "../../../provider/authProvider.js";
import { setUser } from "../../../app/userSlice.js";
import { useDispatch, useSelector } from "react-redux";
const Dashboard = () => {
  const dispatch = useDispatch();
  const { backendAxios } = useAuth();
  const user = useSelector((state) => state.user.userInfo);
  useEffect(() => {
    if (user.store?.shop) {
      backendAxios.get(`/store/find?shop=${user.store.shop}`).then((x) => {
        const updatedUser = { ...user };
        updatedUser.store = x.data;
        dispatch(setUser(updatedUser));
      });
    }
  }, [backendAxios]);
  const revenueData = {
    revenue: 5850,
    roi: 2.3,
  };

  const chartData = [
    {
      name: "Replenishment",
      type: "area",
      yValueFormatString: "#,##0.## Thousand",
      dataPoints: [
        { x: new Date(2024, 4), y: 0 },
        { x: new Date(2024, 5), y: 6.4 },
        { x: new Date(2024, 6), y: 7.3 },
      ],
    },
    {
      name: "Discount Abuse Prevention",
      type: "area",
      yValueFormatString: "#,##0.## Thousand",
      dataPoints: [
        { x: new Date(2024, 4), y: 0 },
        { x: new Date(2024, 5), y: 1 },
        { x: new Date(2024, 6), y: 2 },
      ],
    },
    {
      name: "Winback",
      type: "area",
      yValueFormatString: "#,##0.## Thousand",
      dataPoints: [
        { x: new Date(2024, 4), y: 0 },
        { x: new Date(2024, 5), y: 0.1 },
        { x: new Date(2024, 6), y: 1 },
      ],
    },
  ];

  const toDoItems = [
    {
      text: "Create tags to create highly engaged audience segment",
      checked: true,
    },
    {
      text: "Create tags to filter out repeat customers who will buy soon",
      checked: false,
    },
    {
      text: "Create tags to send replenishment reminder email at the right time",
      checked: false,
    },
    {
      text: "Create tags to run winback automations and marketing campaigns",
      checked: false,
    },
  ];

  const tags = [
    { name: "PurchasedLast30Days", count: 1510 },
    { name: "PurchasedLast14Days", count: 803 },
    { name: "PurchasedPrevious30Days", count: 2060 },
    { name: "PurchasedPrevious60Days", count: 963 },
    { name: "NextPurchaseReminder", count: 55 },
    { name: "ChurnPrevious30Days", count: 1377 },
    { name: "ChurnNext30Days", count: 684 },
    { name: "ChurnPrevious14Days", count: 672 },
  ];
  return (
    <LoggedInPagesTemplate>
      <div style={{ padding: "24px" }}>
        <RevenueCard revenue={revenueData.revenue} roi={revenueData.roi} />
        <OverviewChart data={chartData} />
        <Row gutter={[32, 32]} style={{ marginTop: "32px" }}>
          <Col span={12}>
            <ToDoList items={toDoItems} />
          </Col>
          <Col span={12}>
            <TagsList tags={tags} />
          </Col>
        </Row>
      </div>
    </LoggedInPagesTemplate>
  );
};

export default Dashboard;
