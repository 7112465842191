import React from "react";
import { Row, Col, Typography, Progress } from "antd";

const { Title, Text } = Typography;

const OnboardingStep = ({
  title,
  percent,
  subtitle,
  description,
  children,
  footer,
}) => {
  return (
    <Row style={{ width: "100%", minHeight: "100%" }}>
      <Col span={20} offset={2} style={{ minHeight: "100%" }}>
        <Row
          style={{
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Col>
            <div style={{ minHeight: "10vh", marginTop: "2vh" }}>
              {title && <Title level={4}>{title}</Title>}
              {percent && <Progress percent={percent} showInfo={false} />}
            </div>
            {subtitle && (
              <Title level={2} style={{ marginBottom: "4vh" }}>
                {subtitle}
              </Title>
            )}
            {description && <Text style={{ fontSize: "1.25rem" }}>{description}</Text>}
            {children}
          </Col>
          <Col style={{ marginBottom: "2vh" }}>{footer}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OnboardingStep;
