import React from 'react';
import { Input as AntInput } from 'antd';
import styled from 'styled-components';

const StyledInput = styled(AntInput)`
  font-size: 16px;
  margin-bottom: 10px;
`;

const Input = (props) => {
  return <StyledInput {...props}/>;
};

export default Input;
