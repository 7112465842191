import React from "react";
import { ConfigProvider } from "antd";

const AntdConfigProvider = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#050038",
          colorText: "#000",
          fontFamily: "Inter",
          // fontSize: 20,
        },
        components: {
          Background: { colorPrimary: "#050038" },
          Typography: { colorPrimary: "#050038" },
          Input: {
            borderRadius: 4,
            controlHeight: 48,
            colorPrimary: "#000",
            colorBorder: "#000",
            controlHeightLG: 48,
          },
          Form: {
            controlHeight: 48,
          },
          Progress: {
            colorPrimary: "#0C3F54",
            colorSuccess: "#0C3F54",
          },
          Button: {
            padding: "1.5rem",
            primaryColor: "#000",
            colorPrimaryBg: "#F7F5F2",
            colorPrimary: "#F7F5F2",
            colorPrimaryHover: "#6AAEA5",
            primaryShadow: "none",

            defaultColor: "#FFFFFF",
            defaultBg: "#0C3F54",
            defaultHoverBorderColor: "#FFFFFF",
            defaultHoverColor: "#FFFFFF",
            defaultHoverBg: "#6AAEA5",

            borderRadius: 25,
            borderRadiusLG: 25,
            controlHeightLG: 48,
            fontWeight: 500,
            colorText: "#000",
            boxShadow: "none",
          },
          Menu: {
            itemHeight: 58,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default AntdConfigProvider;
