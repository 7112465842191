export const fetchPricingPlans = async (backendAxios) => {
  try {
    const response = await backendAxios.get(`/plans`);
    return response.data;
  } catch (error) {
    console.error("Error fetching pricing plans:", error);
    throw error;
  }
};

export const fetchPricingPlan = async (backendAxios, id) => {
  try {
    const response = await backendAxios.get(`/plans/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching pricing plan with id ${id}:`, error);
    throw error;
  }
};

export const createPricingPlan = async (backendAxios, planData) => {
  try {
    const response = await backendAxios.post(`/plans`, planData);
    return response.data;
  } catch (error) {
    console.error("Error creating pricing plan:", error);
    throw error;
  }
};

export const updatePricingPlan = async (backendAxios, id, planData) => {
  try {
    const response = await backendAxios.put(`/plans/${id}`, planData);
    return response.data;
  } catch (error) {
    console.error(`Error updating pricing plan with id ${id}:`, error);
    throw error;
  }
};

export const deletePricingPlan = async (backendAxios, id) => {
  try {
    const response = await backendAxios.delete(`/plans/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting pricing plan with id ${id}:`, error);
    throw error;
  }
};
