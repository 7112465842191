import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./userSlice";
import createdTagsReducer from "./createdTagsSlice";

const userPersistConfig = {
  key: "user",
  storage,
};

const persistedUserReducer = persistReducer(userPersistConfig, userReducer);

const createdTagsPersistConfig = {
  key: "createdTags",
  storage,
};

const persistedCreatedTagsReducer = persistReducer(
  createdTagsPersistConfig,
  createdTagsReducer
);

const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    createdTags: persistedCreatedTagsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;
