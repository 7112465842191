import React from "react";
import { Row, Col, Progress } from "antd";

const ProgressDetails = ({ data }) => {
  return (
    <>
      {data &&
        data.items.map((item, index) => (
          <div
            style={{
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            <Progress
              type="circle"
              percent={item.percent}
              showInfo={item.percent === 100}
              size={data.size}
              style={{ marginRight: "10px", position: "relative", top: "-3px" }}
            />
            <span style={{ fontSize: "1.25rem" }}>{item.description}</span>
          </div>
          // <Row style={{ marginBottom: "20px" }} key={index}>
          //   <Col span={2}>
          //     <Progress
          //       type="circle"
          //       percent={item.percent}
          //       showInfo={item.percent === 100}
          //       size={data.size}
          //     />
          //   </Col>
          //   <Col span={18}>{item.description}</Col>
          // </Row>
        ))}
    </>
  );
};

export default ProgressDetails;
