import React, { useEffect, useState } from "react";

import OnboardingTemplate from "../../templates/OnboardingTemplate";
import OnboardingStep from "../../templates/OnboardingStep";
import Guide from "../../templates/Guide";
import { Col, Row } from "antd";
import Button from "../../atoms/Button";
import OnboardingTemplateFooter from "../../organisms/OnboardingTemplateFooter";
import OnboardingInProgressModal from "../../organisms/OnboaringInprogressModal";
import { useAuth } from "../../../provider/authProvider";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../../config/config.js";
import CustomerTag from "../../atoms/CustomerTag.js";
import { Typography } from "antd";
import { useDispatch } from "react-redux";
import { addTag, removeTag } from "../../../app/createdTagsSlice.js";

const { Title } = Typography;

const Step6 = () => {
  const user = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();
  const createdTags = useSelector((state) => state.createdTags?.tags || []);

  const navigate = useNavigate();
  const [confirmationUrl, setConfirmationUrl] = useState(null);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progressPercent, setProgressPercent] = useState([0, 0, 0]);
  const [progressData, setProgressData] = useState({
    size: 24,
    items: [
      {
        percent: progressPercent[0],
        description: "Generating predicted dates for all repeat customers.",
      },
      {
        percent: progressPercent[1],
        description: "Creating tags on Shopify.",
      },
      {
        percent: progressPercent[2],
        description: "Creating tags on Sendlane.",
      },
    ],
  });
  const queryParams = new URLSearchParams(window.location.search);
  const billingActive = queryParams.get("billingActive");

  useEffect(() => {
    setProgressData({
      ...progressData,
      items: progressData.items.map((item, index) => ({
        ...item,
        percent: progressPercent[index],
      })),
    }); // eslint-disable-next-line
  }, [progressPercent]);

  const { backendAxios } = useAuth();
  const nextTagsCriteria = [
    {
      tagName: "ChurnNext7Days",
      dataField: "churnDate",
      days: 7,
      direction: "next",
    },
    {
      tagName: "ChurnNext14Days",
      dataField: "churnDate",
      days: 14,
      direction: "next",
    },
    {
      tagName: "ChurnNext28Days",
      dataField: "churnDate",
      days: 28,
      direction: "next",
    },
  ];
  const previousTagsCriteria = [
    {
      tagName: "ChurnPrevious7Days",
      dataField: "churnDate",
      days: 7,
      direction: "previous",
    },
    {
      tagName: "ChurnPrevious14Days",
      dataField: "churnDate",
      days: 14,
      direction: "previous",
    },
    {
      tagName: "ChurnPrevious28Days",
      dataField: "churnDate",
      days: 28,
      direction: "previous",
    },
  ];
  const [nextSelectedTags, setNextSelectedTags] = useState([
    "ChurnNext7Days",
    "ChurnNext14Days",
    "ChurnNext28Days",
  ]);
  const [previousSelectedTags, setPreviousSelectedTags] = useState([
    "ChurnPrevious7Days",
    "ChurnPrevious14Days",
    "ChurnPrevious28Days",
  ]);
  [...nextSelectedTags, ...previousSelectedTags].map((tag) => {
    dispatch(addTag(tag));
  });
  const handleNextTagsChange = (tag, checked) => {
    const tmpNextSelectedTags = checked
      ? [...nextSelectedTags, tag]
      : nextSelectedTags.filter((t) => t !== tag);

    if (checked) {
      dispatch(addTag(tag));
    } else {
      dispatch(removeTag(tag));
    }

    setNextSelectedTags(tmpNextSelectedTags);
  };
  const handlePreviousTagsChange = (tag, checked) => {
    const tmpPreviousSelectedTags = checked
      ? [...previousSelectedTags, tag]
      : previousSelectedTags.filter((t) => t !== tag);
    if (checked) {
      dispatch(addTag(tag));
    } else {
      dispatch(removeTag(tag));
    }
    setPreviousSelectedTags(tmpPreviousSelectedTags);
  };
  const finishButtonHandler = () => {
    if (user.email != config.testEmail) {
      if (!user.store.isBillingActive && !billingActive) {
        backendAxios
          .post("../../shopify/api/billing/get-charge-link", {
            storeId: user.store._id,
          })
          .then((x) => {
            setConfirmationUrl(x.data.confirmationUrl);
          });
      }
      const requests = [
        ...nextTagsCriteria
          .filter((tag) => nextSelectedTags.includes(tag.tagName))
          .map((criteria) =>
            backendAxios.post("tagsCriteria", {
              ...criteria,
              shop: user.store.shop,
            })
          ),
        ...previousTagsCriteria
          .filter((tag) => previousSelectedTags.includes(tag.tagName))
          .map((criteria) =>
            backendAxios.post("tagsCriteria", {
              ...criteria,
              shop: user.store.shop,
            })
          ),
      ];
      try {
        Promise.all(requests).then(() => {
          setProgressPercent([100, progressPercent[1], progressPercent[2]]);
        });
        backendAxios
          .post("store/intiateStoreCustomerTags", {
            storeId: user.store._id,
          })
          .then(() => {
            setProgressPercent([100, 100, 100]);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setProgressPercent([100, 100, 100]);
      // setTimeout(() => {
      //   navigate("/dashboard");
      // }, 2500);
    }
    setShowProgressModal(true);
  };
  return (
    <>
      <OnboardingTemplate
        showCreatedTags={true}
        leftCol={
          <OnboardingStep
            title="Use Case #4"
            percent={100}
            subtitle="Churn Winback"
          >
            <Guide
              guideData={{
                title: "",
                guideSpan: 24,
                guideSections: [
                  {
                    text: "You will need to decide how to define customers that are nearing churn. You will be able to create a segment of such customers that gets updated dynamically. For example, this customer segment can include customers with the tag ChurnNext14Days and ChurnPrevious14Days to capture them within a 28-day window.",
                  },
                  {
                    text: "You can also send accelerated winback emails the closer each customer gets to churn date or moves past it.",
                  },
                ],
              }}
            />
          </OnboardingStep>
        }
        rightCol={
          <OnboardingStep
            title=""
            percent={null}
            subtitle="Select how you want to identify churning customers"
            footer={
              <OnboardingTemplateFooter
                onContinue={finishButtonHandler}
                buttonText="Finish"
              />
            }
          >
            <Guide
              guideData={{
                title: "",
                guideSpan: 24,
                guideSections: [
                  {
                    text: (
                      <Row>
                        <Col span={24}>
                          <Title level={4}>Customers churning soon:</Title>
                        </Col>
                        <Col span={24}>
                          <Row>
                            {nextTagsCriteria.map((tag) => {
                              return (
                                <Col span={8} style={{ marginTop: "10px" }}>
                                  <CustomerTag
                                    type="default"
                                    style={{ height: "50px" }}
                                    checked={nextSelectedTags.includes(
                                      tag.tagName
                                    )}
                                    onChange={(checked) =>
                                      handleNextTagsChange(tag.tagName, checked)
                                    }
                                    created={createdTags.includes(tag.tagName)}
                                  >
                                    {tag.tagName}
                                  </CustomerTag>
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                      </Row>
                    ),
                  },
                  {
                    text: (
                      <Row>
                        <Col span={24}>
                          <Title level={4}>Customers already churned:</Title>
                        </Col>
                        <Col span={24}>
                          <Row>
                            {previousTagsCriteria.map((tag) => {
                              return (
                                <Col span={8} style={{ marginTop: "10px" }}>
                                  <CustomerTag
                                    type="default"
                                    style={{ height: "50px" }}
                                    checked={previousSelectedTags.includes(
                                      tag.tagName
                                    )}
                                    onChange={(checked) =>
                                      handlePreviousTagsChange(
                                        tag.tagName,
                                        checked
                                      )
                                    }
                                    created={createdTags.includes(tag.tagName)}
                                  >
                                    {tag.tagName}
                                  </CustomerTag>
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                      </Row>
                    ),
                  },
                ],
              }}
            />
          </OnboardingStep>
        }
      />
      <OnboardingInProgressModal
        open={showProgressModal}
        onCancel={() => {
          if (confirmationUrl) {
            window.location.href = confirmationUrl;
          } else {
            navigate("/use-cases/segments");
          }
        }}
        data={progressData}
        btnText={confirmationUrl ? "Start 14 day trial" : "Continue"}
      />
    </>
  );
};

export default Step6;
