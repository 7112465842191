import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Input, Button, Typography, Space, Form, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import LoggedInPagesTemplate from "../../templates/LoggedInPagesTemplate";
import CreatedTags from "../../organisms/CreatedTags";
import { addTag, clearTags } from "../../../app/createdTagsSlice.js";
import { useAuth } from "../../../provider/authProvider.js";

const { Title, Text } = Typography;

const SettingsTags = () => {
  const user = useSelector((state) => state.user.userInfo);
  const { backendAxios } = useAuth();
  const [newTag, setNewTag] = useState("");
  const dispatch = useDispatch();
  const createdTags = useSelector((state) => state.createdTags?.tags || []);
  const [error, setError] = useState("");
  const clearError = () => {
    setError("");
  };
  const handleAddTag = (values) => {
    const { action, when, days } = values;
    const tagName = `${action}${when}${days}Days`;
    const direction = when.toLowerCase();

    const newTag = {
      tagName,
      dataField: tagName.includes("Purchase")
        ? "predictedNextPurchaseDate"
        : "churnDate",
      days: parseInt(days),
      direction,
    };

    if (!createdTags.some((tag) => tag === newTag.tagName)) {
      backendAxios
        .post("/tagsCriteria", { ...newTag, shop: user.store.shop })
        .then((res) => {
          dispatch(addTag(newTag.tagName));
          clearError();
        });
    } else {
      console.log("Tag already exists");
      setError("*Tag already exists");
    }
  };
  useEffect(() => {
    dispatch(clearTags());
    backendAxios.get(`/tagsCriteria?shop=${user.store.shop}`).then((res) => {
      if (res.data && res.data.length > 0) {
        res.data.forEach((tag) => {
          dispatch(addTag(tag.tagName));
        });
      }
    });
  }, []);

  return (
    <LoggedInPagesTemplate>
      <div className="settings-tags">
        <Title level={2}>Tags</Title>
        <Card style={{ marginBottom: "20px" }}>
          <Title level={3}>Current Tags</Title>
          <Space wrap>
            {createdTags.map((tag, index) => (
              <Text
                key={index}
                style={{
                  padding: "3px 7px",
                  borderRadius: "15px",
                  background: "#FFF0F0",
                  border: "1px solid #95928E",
                }}
              >
                {tag}
              </Text>
            ))}
          </Space>
        </Card>
        <Card>
          <Title level={3}>Add New Tag</Title>
          <Form
            layout="inline"
            onFinish={handleAddTag}
            className="add-tag-form"
          >
            <Form.Item name="action" label="Action">
              <Select style={{ width: 120 }} onChange={clearError}>
                <Select.Option value="Purchase">Purchase</Select.Option>
                <Select.Option value="Churn">Churn</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="when" label="When">
              <Select style={{ width: 120 }} onChange={clearError}>
                <Select.Option value="Next">Next</Select.Option>
                <Select.Option value="Previous">Previous</Select.Option>
                {/* Add other options as needed */}
              </Select>
            </Form.Item>
            <Form.Item name="days" label="Days" initialValue={7}>
              <Input
                type="number"
                style={{ width: 60 }}
                onChange={clearError}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                style={{
                  height: "3rem",
                  padding: "0.5rem 2.5rem",
                  borderRadius: "0",
                }}
              >
                Create
              </Button>
            </Form.Item>
          </Form>
          {error && <Text type="danger">{error}</Text>}
        </Card>
      </div>
      {/* <CreatedTags /> */}
    </LoggedInPagesTemplate>
  );
};

export default SettingsTags;
