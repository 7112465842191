import React from "react";

import OnboardingTemplate from "../../templates/OnboardingTemplate";
import OnboardingStep from "../../templates/OnboardingStep";
import Guide from "../../templates/Guide";
import { Col, Row } from "antd";
import CustomerTag from "../../atoms/CustomerTag.js";
import OnboardingTemplateFooter from "../../organisms/OnboardingTemplateFooter";
import { useAuth } from "../../../provider/authProvider";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../../config/config.js";
import Title from "antd/es/typography/Title.js";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addTag, clearTags, removeTag } from "../../../app/createdTagsSlice.js";

const Step3 = () => {
  const { backendAxios } = useAuth();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();
  const createdTags = useSelector((state) => state.createdTags?.tags || []);

  const nextTagsCriteria = [
    {
      tagName: "PurchaseNext7Days",
      dataField: "predictedNextPurchaseDate",
      days: 7,
      direction: "next",
    },
    {
      tagName: "PurchaseNext14Days",
      dataField: "predictedNextPurchaseDate",
      days: 14,
      direction: "next",
    },
    {
      tagName: "PurchaseNext28Days",
      dataField: "predictedNextPurchaseDate",
      days: 28,
      direction: "next",
    },
    {
      tagName: "PurchaseNext15Days",
      dataField: "predictedNextPurchaseDate",
      days: 15,
      direction: "next",
    },
    {
      tagName: "PurchaseNext30Days",
      dataField: "predictedNextPurchaseDate",
      days: 30,
      direction: "next",
    },

    {
      tagName: "PurchaseNext45Days",
      dataField: "predictedNextPurchaseDate",
      days: 45,
      direction: "next",
    },

    {
      tagName: "PurchaseNext60Days",
      dataField: "predictedNextPurchaseDate",
      days: 60,
      direction: "next",
    },
  ];
  const previousTagsCriteria = [
    {
      tagName: "PurchasePrevious7Days",
      dataField: "predictedNextPurchaseDate",
      days: 7,
      direction: "previous",
    },
    {
      tagName: "PurchasePrevious14Days",
      dataField: "predictedNextPurchaseDate",
      days: 14,
      direction: "previous",
    },
    {
      tagName: "PurchasePrevious28Days",
      dataField: "predictedNextPurchaseDate",
      days: 28,
      direction: "previous",
    },
    {
      tagName: "PurchasePrevious15Days",
      dataField: "predictedNextPurchaseDate",
      days: 15,
      direction: "previous",
    },
    {
      tagName: "PurchasePrevious30Days",
      dataField: "predictedNextPurchaseDate",
      days: 30,
      direction: "previous",
    },
    {
      tagName: "PurchasePrevious45Days",
      dataField: "predictedNextPurchaseDate",
      days: 45,
      direction: "previous",
    },
  ];
  const [nextSelectedTags, setNextSelectedTags] = useState([
    "PurchaseNext7Days",
    "PurchaseNext14Days",
    "PurchaseNext28Days",
  ]);
  const [previousSelectedTags, setPreviousSelectedTags] = useState([
    "PurchasePrevious7Days",
    "PurchasePrevious14Days",
    "PurchasePrevious28Days",
  ]);
  [...nextSelectedTags, ...previousSelectedTags].map((tag) => {
    dispatch(addTag(tag));
  });
  const handleNextTagsChange = (tag, checked) => {
    const tmpNextSelectedTags = checked
      ? [...nextSelectedTags, tag]
      : nextSelectedTags.filter((t) => t !== tag);

    if (checked) {
      dispatch(addTag(tag));
    } else {
      dispatch(removeTag(tag));
    }

    setNextSelectedTags(tmpNextSelectedTags);
  };
  const handlePreviousTagsChange = (tag, checked) => {
    const tmpPreviousSelectedTags = checked
      ? [...previousSelectedTags, tag]
      : previousSelectedTags.filter((t) => t !== tag);

    if (checked) {
      dispatch(addTag(tag));
    } else {
      dispatch(removeTag(tag));
    }

    setPreviousSelectedTags(tmpPreviousSelectedTags);
  };
  const useCasesNextHandler = () => {
    if (user.email != config.testEmail) {
      const requests = [
        ...nextTagsCriteria.filter((tag) =>
          nextSelectedTags.includes(tag.tagName)
        ),
        ...previousTagsCriteria.filter((tag) =>
          previousSelectedTags.includes(tag.tagName)
        ),
      ].map((criteria) => {
        return backendAxios.post("tagsCriteria", {
          ...criteria,
          shop: user.store.shop,
        });
      });
      Promise.all(requests)
        .then(() => {
          console.log("All tags criteria have been posted successfully");
        })
        .catch((error) => {
          console.error("Error posting tags criteria:", error);
        });
    }

    navigate("/onboarding/step4");
  };
  return (
    <OnboardingTemplate
      showCreatedTags={true}
      leftCol={
        <OnboardingStep
          title="Use Case #1"
          percent={25}
          subtitle="Send marketing emails at the right time"
        >
          <Guide
            guideData={{
              title: "",
              guideSpan: 24,
              guideSections: [
                {
                  text: "You will need to decide how to define audience segment that is nearing purchasing from you and for how long they are past their expected purchase date.",
                },
                {
                  text: "You will be able to create a segment of such customers that gets updated dynamically. For example, this customer segment can include customers with the tag PurchaseNext28Days and PurchasePrevious28Days to capture them within a 56-day window.",
                },
                {
                  text: "You can also use this data to accelerate the frequency of campaigns as each customer nears their expected purchase date.",
                },
              ],
            }}
          />
        </OnboardingStep>
      }
      rightCol={
        <OnboardingStep
          title=""
          percent={null}
          subtitle="Select how you want to identify your customers"
          footer={<OnboardingTemplateFooter onContinue={useCasesNextHandler} />}
        >
          <Guide
            guideData={{
              title: "",
              guideSpan: 24,
              guideSections: [
                {
                  text: (
                    <Row>
                      <Col span={24}>
                        <Title level={4}>
                          Customers nearing purchase date:
                        </Title>
                      </Col>
                      <Col span={24}>
                        <Row>
                          {nextTagsCriteria.map((tag) => {
                            return (
                              <Col span={8} style={{ marginTop: "10px" }}>
                                <CustomerTag
                                  type="default"
                                  style={{ height: "50px" }}
                                  checked={nextSelectedTags.includes(
                                    tag.tagName
                                  )}
                                  onChange={(checked) =>
                                    handleNextTagsChange(tag.tagName, checked)
                                  }
                                  created={createdTags.includes(tag.tagName)}
                                >
                                  {tag.tagName}
                                </CustomerTag>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    </Row>
                  ),
                },
                {
                  text: (
                    <Row>
                      <Col span={24}>
                        <Title level={4}>
                          Customers past expected purchase date:
                        </Title>
                      </Col>
                      <Col span={24}>
                        <Row>
                          {previousTagsCriteria.map((tag) => {
                            return (
                              <Col span={8} style={{ marginTop: "10px" }}>
                                <CustomerTag
                                  type="default"
                                  style={{ height: "50px" }}
                                  checked={previousSelectedTags.includes(
                                    tag.tagName
                                  )}
                                  onChange={(checked) =>
                                    handlePreviousTagsChange(
                                      tag.tagName,
                                      checked
                                    )
                                  }
                                  created={createdTags.includes(tag.tagName)}
                                >
                                  {tag.tagName}
                                </CustomerTag>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    </Row>
                  ),
                },
              ],
            }}
          />
        </OnboardingStep>
      }
    />
  );
};

export default Step3;
