import React from "react";
import styled from "styled-components";
import "./App.css";
import AntdConfigProvider from "./provider/antdConfigProvider";
import AuthProvider from "./provider/authProvider";
import Routes from "./routes/routes";

const AppWrapper = styled.div`
  background-color: #fff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const App = () => {
  return (
    <AppWrapper>
      <AntdConfigProvider>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </AntdConfigProvider>
    </AppWrapper>
  );
};

export default App;
