import React, { useEffect, useState } from "react";
import LoggedInPagesTemplate from "../../templates/LoggedInPagesTemplate";
import {
  Row,
  Col,
  Typography,
  Progress,
  Switch,
  Card,
  Button,
  Checkbox,
  Select,
} from "antd";
import BillingPlanCard from "../../molecules/BillingPlanCard";
import DashboardTitle from "../../atoms/Title";
import "../style.css";
import { ContactsOutlined } from "@ant-design/icons";
import { fetchPricingPlans } from "../../../api/pricingPlans";
import { useAuth } from "../../../provider/authProvider";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../app/userSlice.js";
import { formatDate } from "../../../config/utils";
import { getStoreCustomerCount } from "../../../api/store";

const { Title, Text } = Typography;
const { Option } = Select;

const supportTiers = {
  professional: {
    key: "professional",
    displayName: "Professional",
    contacts: "30k - 150k contacts",
    description: [
      "Assisted onboarding support.",
      "Access to 24/7/365 customer support via email and chat.",
    ],
  },
  enterprise: {
    key: "enterprise",
    displayName: "Enterprise",
    contacts: "150k+ contacts",
    description: [
      "Everything in Professional Support.",
      "Custom onboarding.",
      "Dedicated Customer Success Manager.",
      "Program review 1x per month.",
    ],
  },
};

const SettingsBilling = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userInfo);
  const { backendAxios } = useAuth();
  const [pricingPlans, setPricingPlans] = useState([]);
  const [customerCount, setCustomerCount] = useState(0);

  const [userPlan, setUserPlan] = useState(user.store.plan);
  const [selectedPlan, setSelectedPlan] = useState(userPlan);

  useEffect(() => {
    fetchPricingPlans(backendAxios).then((plans) => {
      setPricingPlans(plans);
    });
  }, [backendAxios]);
  useEffect(() => {
    setUserPlan(user.store.plan);
  }, [user]);
  const updateUser = () => {
    backendAxios.get(`/store/find?shop=${user.store.shop}`).then((x) => {
      const updatedUser = { ...user };
      updatedUser.store = x.data;
      dispatch(setUser(updatedUser));
      setUserPlan(x.data.plan);
      setSelectedPlan(x.data.plan);
    });
  };

  useEffect(() => {
    updateUser();
  }, []);

  const handleUpgrade = async (duration) => {
    const request = {
      storeId: user.store._id,
      plan: {
        displayName: `${selectedPlan.displayName} - ${duration}`,
        key: selectedPlan.key,
        duration: duration,
      },
      // redirectToDashboard: true,
      redirectToUrl: "/settings/billing",
    };
    const response = await backendAxios.post(
      "../../shopify/api/billing/get-charge-link",
      request
    );
    window.location.href = response.data.confirmationUrl;
  };

  useEffect(() => {
    getStoreCustomerCount(backendAxios, user.store.shop).then((x) => {
      if (typeof x !== "undefined") {
        setCustomerCount(x.count);
      }
    });
  }, [backendAxios, user]);

  const styleVAlign = {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  };
  const updateUserStore = () => {
    if (user.store?.shop) {
      backendAxios.get(`/store/find?shop=${user.store.shop}`).then((x) => {
        const updatedUser = { ...user };
        updatedUser.store = x.data;
        dispatch(setUser(updatedUser));
      });
    }
  };
  const handlePlanChange = (value) => {
    const plan = pricingPlans.find((plan) => plan.key === value);
    setSelectedPlan(plan);
  };
  const handleCancelSubscription = () => {
    backendAxios
      .post("../../shopify/api/billing/cancel-subscription", {
        storeId: user.store._id,
      })
      .then(() => {
        updateUserStore();
      });
  };
  const trialDaysLeft = Math.ceil(
    (new Date(user.store.planStartDate) - new Date()) / (1000 * 60 * 60 * 24)
  );

  return (
    <LoggedInPagesTemplate>
      {selectedPlan && (
        <div className="settings-billing">
          <DashboardTitle>Billing</DashboardTitle>
          <Card style={{ marginBottom: "20px", backgroundColor: "#F5F5F5" }}>
            <Row>
              <Col span={12}>
                <Title level={3}>Current Plan</Title>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                {trialDaysLeft > 0 && (
                  <span
                    style={{
                      fontSize: "small",
                      color: "#000",
                      backgroundColor: "#CDE4E1",
                      padding: "1vh 2vw",
                    }}
                    title="This is a free trial. You will be charged for your plan when the trial ends."
                  >
                    {trialDaysLeft} days left on Free Trial
                  </span>
                )}
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={9}>
                <Title level={4}>
                  <ContactsOutlined />
                  &nbsp;{userPlan.displayName}
                </Title>
              </Col>
              <Col span={4} style={{ ...styleVAlign }}>
                <div>
                  <span>Cost:</span>
                  <Title level={5}>
                    $
                    {user.store.planDuration == "Annual"
                      ? userPlan.annualPricing / 12
                      : userPlan.monthlyPricing}{" "}
                    per month
                  </Title>
                </div>
              </Col>
              <Col
                span={8}
                offset={3}
                style={{ ...styleVAlign, justifyContent: "flex-end" }}
              >
                <Button
                  type="link"
                  style={{ color: "#95928E" }}
                  onClick={() => handleCancelSubscription()}
                >
                  Cancel Subscription
                </Button>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={9}>
                <Title level={5}>Contacts</Title>
                <Progress
                  percent={(customerCount / userPlan.maxContacts) * 100}
                  status="active"
                  showInfo={false}
                  percentPosition={{ align: "end", type: "inner" }}
                  size={[300, 20]}
                  strokeLinecap="square"
                  strokeColor="#000000"
                />
                <Text>
                  {customerCount.toLocaleString()}/
                  {userPlan.maxContacts.toLocaleString()}
                </Text>
              </Col>
              <Col span={4} style={{ ...styleVAlign }}>
                <div>
                  <span>Subscription Start</span>
                  <Title level={5}>
                    {formatDate(user.store.planStartDate)}
                  </Title>
                </div>
              </Col>
              <Col span={4} style={{ ...styleVAlign }}>
                <div>
                  <span>{user.store.autoRenew ? "Renews" : "Expires"}</span>
                  <Title level={5}>
                    {formatDate(user.store.planRenewDate)}
                  </Title>
                </div>
              </Col>
              <Col
                span={7}
                style={{ ...styleVAlign, justifyContent: "flex-end" }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: "right" }}>
                    <span style={{ color: "#95928E" }}>
                      When contact limit is reached
                    </span>
                  </Col>
                  <Col
                    span={24}
                    style={{ textAlign: "right", color: "#95928E" }}
                  >
                    <Checkbox defaultChecked /> Upgrade to next plan
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          <Title level={3}>Plans</Title>
          <Select
            className="select-plan-dropdown"
            style={{
              width: "100%",
              marginBottom: "2vh",
              height: "5vh",
            }}
            value={selectedPlan.key}
            onChange={handlePlanChange}
          >
            {pricingPlans.map((plan) => (
              <Option key={plan.key} value={plan.key}>
                {plan.displayName}
              </Option>
            ))}
          </Select>
          {selectedPlan && (
            <Row gutter={16}>
              <Col span={8}>
                <BillingPlanCard
                  title="Annual"
                  price={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Title level={4} style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "2rem", fontWeight: "bold" }}>
                          ${(selectedPlan.annualPricing / 12).toFixed(2)}
                        </span>{" "}
                        per month
                      </Title>
                      <span
                        style={{
                          fontSize: "small",
                          color: "#000",
                          backgroundColor: "#CDE4E1",
                          padding: "1vh 2vw",
                        }}
                      >
                        Save $
                        {selectedPlan.monthlyPricing * 12 -
                          selectedPlan.annualPricing}{" "}
                        Instantly!
                      </span>
                    </div>
                  }
                  description={
                    <ul>
                      <li>
                        Billed annually at $
                        {selectedPlan.annualPricing.toLocaleString()}
                      </li>
                      <li>Unlimited Emails</li>
                      <li>
                        Up to {selectedPlan.maxContacts.toLocaleString()}{" "}
                        Contacts
                      </li>
                      <li>{selectedPlan.supportTier} Support</li>
                    </ul>
                  }
                  buttonText={
                    selectedPlan.key == userPlan.key &&
                    user.store.planDuration == "Annual"
                      ? null
                      : "Upgrade"
                  }
                  onClick={() => handleUpgrade("Annual")}
                />
              </Col>
              <Col span={8}>
                <BillingPlanCard
                  title="Monthly"
                  price={
                    <Title level={4} style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "2rem", fontWeight: "bold" }}>
                        ${selectedPlan.monthlyPricing.toFixed(2)}
                      </span>{" "}
                      per month
                    </Title>
                  }
                  description={
                    <ul>
                      <li>Unlimited Emails</li>
                      <li>
                        Up to {selectedPlan.maxContacts.toLocaleString()}{" "}
                        Contacts
                      </li>
                      <li>{selectedPlan.supportTier} Support</li>
                    </ul>
                  }
                  buttonText={
                    selectedPlan.key == userPlan.key &&
                    user.store.planDuration == "Monthly"
                      ? null
                      : "Upgrade"
                  }
                  onClick={() => handleUpgrade("Monthly")}
                />
              </Col>
              <Col span={8}>
                <BillingPlanCard
                  title="Your Support Tier"
                  price={
                    <Title level={4} style={{ textAlign: "center" }}>
                      <span style={{ fontSize: "2rem", fontWeight: "bold" }}>
                        {selectedPlan.supportTier}
                      </span>
                    </Title>
                  }
                  description={
                    <ul>
                      {supportTiers[
                        selectedPlan.supportTier.toLowerCase()
                      ].description.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  }
                  onClick={() => console.log("Upgrade Support Tier")}
                />
              </Col>
            </Row>
          )}
          <Title level={3} style={{ marginTop: "40px" }}>
            Support Tiers
          </Title>
          <Row gutter={16}>
            {Object.keys(supportTiers).map((key) => (
              <Col span={8} key={key}>
                <BillingPlanCard
                  title={supportTiers[key].displayName}
                  price={supportTiers[key].contacts}
                  description={
                    <ul>
                      {supportTiers[key].description.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  }
                />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </LoggedInPagesTemplate>
  );
};

export default SettingsBilling;
