import React from "react";
import { Row, Col } from "antd";
import CreatedTags from "../organisms/CreatedTags";

const OnboardingTemplate = ({ leftCol, rightCol, showCreatedTags = false }) => {
  const rowStyle = {
    height: "100vh",
    width: "100vw",
  };
  const colStyle = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    // overflowY: "scroll",
    minHeight: "100vh",
    padding: "4vw 4vh 10px",
  };
  return (
    <Row style={rowStyle}>
      <Col span={12} style={colStyle}>
        {leftCol}
      </Col>
      <Col span={12} style={{ ...colStyle, backgroundColor: "#CDE4E1" }}>
        {showCreatedTags && <CreatedTags />}
        {rightCol}
      </Col>
    </Row>
  );
};

export default OnboardingTemplate;
