import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import SignInPage from "../components/pages/SignInPage";
import SignUpPage from "../components/pages/SignUpPage";
import Step1 from "../components/pages/Onboarding/Step1";
import Step2 from "../components/pages/Onboarding/Step2";
import Step3 from "../components/pages/Onboarding/Step3_uc1";
import Step4 from "../components/pages/Onboarding/Step4_uc2";
import Step5 from "../components/pages/Onboarding/Step5_uc3";
import Step6 from "../components/pages/Onboarding/Step6_uc4";
import UseCaseSegments from "../components/pages/LoggedInPages/UseCaseSegments";
import UseCaseAbandonedCart from "../components/pages/LoggedInPages/UseCaseAbandonedCart";
import UseCaseReplenishment from "../components/pages/LoggedInPages/UseCaseRepleneshment";
import { Navigate } from "react-router-dom";
import Dashboard from "../components/pages/LoggedInPages/Dashboard";
import UseCaseChurn from "../components/pages/LoggedInPages/UseCaseChurn";
import SettingsBilling from "../components/pages/LoggedInPages/SettingsBilling";
import SettingsTags from "../components/pages/LoggedInPages/SettingsTags";

const Routes = () => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/",
      element: <Navigate to="/login" />,
    },
    {
      path: "/login",
      element: <SignInPage />,
    },
    {
      path: "/signup",
      element: <SignUpPage />,
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/onboarding/step1",
          element: <Step1 />,
        },
        {
          path: "/onboarding/step2",
          element: <Step2 />,
        },
        {
          path: "/onboarding/step3",
          element: <Step3 />,
        },
        {
          path: "/onboarding/step4",
          element: <Step4 />,
        },
        {
          path: "/onboarding/step5",
          element: <Step5 />,
        },
        {
          path: "/onboarding/step6",
          element: <Step6 />,
        },
        {
          path: "/use-cases/segments",
          element: <UseCaseSegments />,
        },
        {
          path: "/use-cases/abandoned-cart",
          element: <UseCaseAbandonedCart />,
        },
        {
          path: "/use-cases/replenishment",
          element: <UseCaseReplenishment />,
        },
        {
          path: "/use-cases/churn",
          element: <UseCaseChurn />,
        },
        {
          path: "/settings/billing",
          element: <SettingsBilling />,
        },
        {
          path: "/settings/tags",
          element: <SettingsTags />,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
