import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import SignInForm from "../organisms/SignInForm";

const SignInWrapper = styled.div`
  height: 100vh;
  background-color: #0b0b45;
  padding: 20px;
  width: 100%;
`;

const SignInTemplate = () => {
  return (
    <SignInWrapper>
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <img
          src={"webapp/login/Blue-Bg-Small.svg"}
          loading="lazy"
          style={{
            zIndex: 0,
            width: "40%",
            maxWidth: "378px",
            position: "absolute",
            top: "auto",
            bottom: "0%",
            left: "0%",
            right: "auto",
          }}
        />
        <Col span={24} md={12} lg={8} xl={6} xxl={6}>
          <SignInForm />
        </Col>
        <img
          src="webapp/login/Blue-Bg-Large.svg"
          loading="lazy"
          alt=""
          style={{
            zIndex: 0,
            objectFit: "cover",
            width: "35%",
            maxWidth: "328px",
            height: "100%",
            position: "absolute",
            top: "0%",
            bottom: "0%",
            left: "auto",
            right: "0%",
          }}
        />
      </Row>
    </SignInWrapper>
  );
};

export default SignInTemplate;
