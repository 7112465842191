import { Affix } from "antd";
import React, { useState } from "react";

const OverflowContainer = ({ affixChildren, children }) => {
  const [guideContainer, setGuideContainer] = useState(null);
  return (
    <>
      <div className="overflow-container" ref={setGuideContainer}>
        {children}
      </div>
      <Affix target={() => guideContainer} offsetTop={0} style={{ marginTop: "10px"}}>
        {affixChildren}
      </Affix>
    </>
  );
};

export default OverflowContainer;
