import React from "react";
import Input from "../atoms/Input";
import { Form } from "antd";

const FormField = ({ label, type, icon, name, ...props }) => {
  console.log(props);
  const { itemProps, ...inputProps } = props;
  return (
    <Form.Item label={label} name={name} {...itemProps}>
      <Input type={type} {...inputProps} />
    </Form.Item>
  );
};

export default FormField;
