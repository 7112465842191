import React from "react";
import { Card } from "antd";
import ToDoItem from "../../molecules/Dashbaord/ToDoItem";

import "../style.css";
import { Typography } from "antd";
const { Title } = Typography;
const ToDoList = ({ items }) => {
  return (
    <Card
      className="to-do-list"
      title={<Title level={4}>Things to do next</Title>}
      style={{ border: "none", backgroundColor: "#F9F9F9", padding: "16px 50px 16px 16px" }}
    >
      {items.map((item, index) => (
        <ToDoItem key={index} text={item.text} checked={item.checked} />
      ))}
    </Card>
  );
};

export default ToDoList;
