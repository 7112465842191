import React from "react";
import DashboardCheckbox from "../../atoms/DashboardCheckbox";

const ToDoItem = ({ text, checked }) => {
  return (
    <div style={{ marginBottom: "15px" }}>
      <DashboardCheckbox
        checked={checked}
        style={{
          fontSize: "1.25rem",
          textDecoration: checked ? "line-through" : "none",
          color: checked ? "#808080" : "black",
        }}
      >
        {text}
      </DashboardCheckbox>
    </div>
  );
};

export default ToDoItem;
