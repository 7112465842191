import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const DashboardText = ({ children, align }) => {
  return <Text>{children}</Text>;
};

export default DashboardText;
