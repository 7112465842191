import React from "react";
import { Row, Col } from "antd";
import DashboardText from "../../atoms/Text";
import Button from "../../atoms/Button";

const TagItem = ({ tag, count }) => {
  return (
    <Row style={{ marginBottom: "12px" }}>
      <Col span={16}>
        <Button
          type="secondary"
          style={{
            height: "40px",
            fontSize: "14px",
            border: "1px solid #E0E0E0",
          }}
        >
          {tag}
        </Button>
      </Col>
      <Col span={8}>
        <DashboardText>{count}</DashboardText>
      </Col>
    </Row>
  );
};

export default TagItem;
