import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import exportConfig from "../config/config";
import { useDispatch, useSelector } from "react-redux";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // Add a response interceptor to handle errors
  // backendAxios.interceptors.response.use(
  //   (response) => response,
  //   (error) => {
  //     // Handle specific error codes
  //     if (error.response) {
  //       switch (error.response.status) {
  //         case 401:
  //           // Unauthorized: Clear token and redirect to login
  //           setToken(null);
  //           // You might want to use a navigation method here, e.g., window.location.href = '/login';
  //           break;
  //         case 403:
  //           // Forbidden: You might want to show a message or redirect
  //           console.error("Access forbidden");
  //           break;
  //         default:
  //           console.error("An error occurred:", error.response.data);
  //       }
  //     } else if (error.request) {
  //       // The request was made but no response was received
  //       console.error("No response received:", error.request);
  //     } else {
  //       // Something happened in setting up the request that triggered an Error
  //       console.error("Error setting up the request:", error.message);
  //     }
  //     // return Promise.reject(error);
  //     return {
  //       success: false,
  //       message: error.message,
  //     };
  //   }
  // );

  const user = useSelector((state) => state.user.userInfo);
  // State to hold the authentication token
  const [token, setToken_] = useState(
    localStorage.getItem("token") || (user && user.jwtToken)
  );
  // Function to set the authentication token
  const setToken = (newToken) => {
    setToken_(newToken);
  };
  let axiosPayload = {
    baseURL: exportConfig.backendHost,
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    axiosPayload.headers.Authorization = "Bearer " + token;
  }
  let backendAxios = axios.create(axiosPayload);
  useEffect(() => {
    if (token) {
      backendAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.setItem("token", token);
    } else {
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
    } // eslint-disable-next-line
  }, [token]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      backendAxios,
    }), // eslint-disable-next-line
    [token]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
