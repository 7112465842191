import React from "react";
import { Form, Checkbox } from "antd";
import FormField from "../molecules/FormField";
import Button from "../atoms/Button";
import styled from "styled-components";
import { LockFilled, MailFilled, UserOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../provider/authProvider";

import { useDispatch } from "react-redux";
import { setUser } from "../../app/userSlice";
const SignUpFormWrapper = styled.div`
  background: #ffffff;
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  text-align: left;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 16px;
  }
`;

const SignUpForm = () => {
  const dispatch = useDispatch();
  const { setToken, backendAxios } = useAuth();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const shop = queryParams.get("shop");

  if (shop) {
    const newUrl = new URL(window.location);
    newUrl.searchParams.delete("shop");
    window.history.replaceState(null, "", newUrl);
  }

  const handleSignUp = (values) => {
    backendAxios.post("auth/register", values).then((res) => {
      setToken(res.data.jwtToken);
      dispatch(setUser(res.data));
      navigate("/onboarding/step1");
    });
  };
  return (
    <SignUpFormWrapper>
      <Title
        level={2}
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginBottom: "60px",
        }}
      >
        Create an account
      </Title>
      <StyledForm onFinish={handleSignUp}>
        {/* <Button type="primary" style={{ marginBottom: "20px" }}>
          Sign up with Google
        </Button> */}
        {shop && <FormField name="shop" value={shop} hidden />}
        <FormField
          placeholder="Your full name"
          prefix={<UserOutlined style={{ fontSize: "22px" }} />}
          size="large"
          name="name"
        />
        <FormField
          placeholder="Enter your email"
          prefix={<MailFilled style={{ fontSize: "22px" }} />}
          size="large"
          name="email"
        />
        <FormField
          type="password"
          placeholder="Password"
          prefix={<LockFilled style={{ fontSize: "22px" }} />}
          size="large"
          name="password"
        />
        <Form.Item labelAlign="left">
          <Checkbox>Remember Password</Checkbox>
        </Form.Item>
        <Button
          // type="primary"
          size="large"
          style={{
            width: "100%",
            fontSize: "20px",
            fontWeight: "bold",
            paddingTop: "14px",
            paddingBottom: "14px",
            height: "56px",
          }}
          htmlType="submit"
        >
          Register
        </Button>
      </StyledForm>
      <p style={{ textAlign: "center", marginTop: "20px" }}>
        Already have an account? <a href="/login">Sign In</a>
      </p>
    </SignUpFormWrapper>
  );
};

export default SignUpForm;
