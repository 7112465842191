import React from "react";
import { Card } from "antd";
import Meta from "antd/es/card/Meta";

const PlatformCard = ({ imgSrc, name, onClick, disabled }) => {
  return (
    <Card
      hoverable={!disabled}
      style={{
        textAlign: "center",
        padding: "20px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        backgroundColor: disabled ? "#E0E0E0" : "#FFF0F0", // Change background color if disabled
        borderRadius: "25px",
        cursor: disabled ? "not-allowed" : "pointer", // Change cursor if disabled
        opacity: disabled ? 0.5 : 1, // Change opacity if disabled
      }}
      cover={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "80px",
            height: "80px",
            background: imgSrc
              ? `url(${imgSrc}) no-repeat center center`
              : "#E0E0E0",
            backgroundSize: "contain",
            filter: disabled ? "grayscale(100%)" : "none",
          }}
        ></div>
      }
      styles={{
        body: {
          padding: "5px",
        },
      }}
      onClick={!disabled ? onClick : undefined} // Prevent click if disabled
    >
      <Meta title={name} />
    </Card>
  );
};

export default PlatformCard;