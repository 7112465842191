import React, { useEffect, useState } from "react";
import { Form, Checkbox, Row, Col, message } from "antd";
import FormField from "../molecules/FormField";
import Button from "../atoms/Button";
import styled from "styled-components";
import { LockFilled, MailFilled } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { useAuth } from "../../provider/authProvider";
import { useNavigate } from "react-router-dom";
import Logo from "../atoms/Logo.js";
import { useDispatch } from "react-redux";
import { setUser } from "../../app/userSlice.js";
import config from "../../config/config.js";
import { clearTags } from "../../app/createdTagsSlice.js";

const SignInFormWrapper = styled.div`
  background: #ffffff;
  padding: 30px;
  border-radius: 15px;
  width: 100%;
  max-width: 500px;
  text-align: left;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 16px;
  }
`;

const SignInForm = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const { setToken, backendAxios } = useAuth();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [shop, setShop] = useState();

  useEffect(() => {
    if (queryParams.get("shop")) {
      setShop(queryParams.get("shop"));
    }
  }, [queryParams]);
  if (shop) {
    const newUrl = new URL(window.location);
    newUrl.searchParams.delete("shop");
    dispatch(clearTags());
    window.history.replaceState(null, "", newUrl);
  }
  const handleLogin = (values) => {
    backendAxios
      .post("auth/login", values)
      .then((res) => {
        console.log("res", res);
        setToken(res.data.jwtToken);
        dispatch(setUser(res.data));
        if (res.data.store.isBillingActive) {
          navigate("/dashboard");
        } else if (res.data.store.source !== "app") {
          navigate(
            `/onboarding/step2?shop=${res.data.store.shop}&update=false&storeId=${res.data.store._id}`
          );
        } else {
          navigate("/onboarding/step1");
        }
        //  else {
        //   navigate("/dashboard");
        // }
      })
      .catch((err) => {
        console.log(123);
        console.log(err);
        messageApi.open({
          type: "error",
          content: "Invalid email or password",
        });
      });
  };
  return (
    <SignInFormWrapper>
      {contextHolder}
      <Row justify="center" align="middle">
        <Col span={24} md={12} lg={8} xl={6} xxl={6}>
          <Logo />
        </Col>
      </Row>
      <Title
        level={2}
        style={{
          fontFamily: "PlusJakartaBold",
          textAlign: "center",
          fontWeight: "bold",
          marginTop: 0,
          marginBottom: "50px",
        }}
      >
        Sign in to Beena
      </Title>
      <StyledForm onFinish={handleLogin}>
        {/* <Button type="primary" style={{ marginBottom: "20px" }}>
          Sign up with Google
        </Button> */}
        {shop && (
          <FormField
            name="shop"
            itemProps={{ hidden: true, initialValue: shop }}
          />
        )}
        <FormField
          placeholder="Enter your email"
          prefix={<MailFilled style={{ fontSize: "22px" }} />}
          name="email"
        />
        <FormField
          type="password"
          placeholder="Password"
          prefix={<LockFilled style={{ fontSize: "22px", color: "#000" }} />}
          name="password"
        />
        <Form.Item labelAlign="left">
          <Checkbox>Remember Password</Checkbox>
        </Form.Item>
        <Button
          size="large"
          htmlType="submit"
          style={{
            width: "100%",
            fontSize: "20px",
            fontWeight: "bold",
            paddingTop: "14px",
            paddingBottom: "14px",
            height: "56px",
          }}
        >
          Login
        </Button>
      </StyledForm>
      <p style={{ textAlign: "center", marginTop: "20px" }}>
        Don't have an account?{" "}
        <a
          href={shop ? `/signup?shop=${shop}` : "/signup"}
          style={{ fontWeight: "500" }}
        >
          Sign Up
        </a>
        <br />
        <a href="/forgot-password" style={{ fontWeight: "600" }}>
          Forgot Password?
        </a>
      </p>
    </SignInFormWrapper>
  );
};

export default SignInForm;
