import React from "react";

import OnboardingTemplate from "../../templates/OnboardingTemplate";
import OnboardingStep from "../../templates/OnboardingStep";
import Guide from "../../templates/Guide";
import { Col, Row } from "antd";
import OnboardingTemplateFooter from "../../organisms/OnboardingTemplateFooter";
import { useAuth } from "../../../provider/authProvider";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../../config/config.js";
import CustomerTag from "../../atoms/CustomerTag.js";
import { useState } from "react";
import Title from "antd/es/typography/Title.js";
import { useDispatch } from "react-redux";
import { addTag, removeTag } from "../../../app/createdTagsSlice.js";

const Step4 = () => {
  const user = useSelector((state) => state.user.userInfo);
  const createdTags = useSelector((state) => state.createdTags?.tags || []);
  const { backendAxios } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tagsCriteria = [
    {
      tagName: "PurchaseNext7Days",
      dataField: "predictedNextPurchaseDate",
      days: 7,
      direction: "next",
    },
    {
      tagName: "PurchaseNext10Days",
      dataField: "predictedNextPurchaseDate",
      days: 10,
      direction: "next",
    },
    {
      tagName: "PurchaseNext14Days",
      dataField: "predictedNextPurchaseDate",
      days: 14,
      direction: "next",
    },
    {
      tagName: "PurchasePrevious7Days",
      dataField: "predictedNextPurchaseDate",
      days: 7,
      direction: "previous",
    },
    {
      tagName: "PurchasePrevious10Days",
      dataField: "predictedNextPurchaseDate",
      days: 10,
      direction: "previous",
    },

    {
      tagName: "PurchasePrevious14Days",
      dataField: "predictedNextPurchaseDate",
      days: 14,
      direction: "previous",
    },
  ];
  const [selectedTags, setSelectedTags] = useState([
    "PurchaseNext7Days",
    "PurchaseNext10Days",
    "PurchaseNext14Days",
    "PurchasePrevious7Days",
    "PurchasePrevious10Days",
    "PurchasePrevious14Days",
  ]);
  [...selectedTags].map((tag) => {
    dispatch(addTag(tag));
  });
  const handleTagsChange = (tag, checked) => {
    const tmpSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    if (checked) {
      dispatch(addTag(tag));
    } else {
      dispatch(removeTag(tag));
    }

    setSelectedTags(tmpSelectedTags);
  };
  const useCasesNextHandler = () => {
    if (user.email != config.testEmail) {
      const requests = tagsCriteria
        .filter((tag) => selectedTags.includes(tag.tagName))
        .map((criteria) =>
          backendAxios.post("tagsCriteria", {
            ...criteria,
            shop: user.store.shop,
          })
        );
      Promise.all(requests)
        .then(() => {
          console.log("All tags criteria have been posted successfully");
        })
        .catch((error) => {
          console.error("Error posting tags criteria:", error);
        });
    }
    navigate("/onboarding/step5");
  };
  return (
    <OnboardingTemplate
      showCreatedTags={true}
      leftCol={
        <OnboardingStep
          title="Use Case #2"
          percent={50}
          subtitle="Stop Abandoned Cart Discount Abuse"
        >
          <Guide
            guideData={{
              title: "",
              guideSpan: 24,
              guideSections: [
                {
                  text: "You can reuse tags created previously or generate new ones. This window is shorter and should span at least a week or up to 28/30 days from the date the customer adds to cart.",
                },
                {
                  text: "You will be able to add a conditional split to your existing abandoned cart automation and for people who are expected to purchase soon, you can send them a different email/offer while still sending the original offer to rest of your shoppers.",
                },
              ],
            }}
          />
        </OnboardingStep>
      }
      rightCol={
        <OnboardingStep
          title=""
          percent={null}
          subtitle="Select how you want to identify your customers"
          footer={<OnboardingTemplateFooter onContinue={useCasesNextHandler} />}
        >
          <Guide
            guideData={{
              title: "",
              guideSpan: 24,
              guideSections: [
                {
                  text: (
                    <Row>
                      <Col span={24}>
                        <Title level={4}>
                          Customers close to purchase date:
                        </Title>
                      </Col>
                      <Col span={24}>
                        <Row>
                          {tagsCriteria.map((tag) => {
                            return (
                              <Col span={8} style={{ marginTop: "10px" }}>
                                <CustomerTag
                                  type="default"
                                  style={{ height: "50px" }}
                                  checked={selectedTags.includes(tag.tagName)}
                                  onChange={(checked) =>
                                    handleTagsChange(tag.tagName, checked)
                                  }
                                  created={createdTags.includes(tag.tagName)}
                                >
                                  {tag.tagName}
                                </CustomerTag>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    </Row>
                  ),
                },
              ],
            }}
          />
        </OnboardingStep>
      }
    />
  );
};

export default Step4;
