// src/app/createdTagsSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const createdTagsSlice = createSlice({
  name: 'createdTags',
  initialState: {
    tags: [],
  },
  reducers: {
    addTag: (state, action) => {
      if (!state.tags.includes(action.payload)) {
        state.tags.push(action.payload);
      }
    },
    removeTag: (state, action) => {
      state.tags = state.tags.filter(tag => tag !== action.payload);
    },
    getTags: (state) => {
      return state.tags;
    },
    clearTags: (state) => {
      state.tags = [];
    },
  },
});

export const { addTag, removeTag, clearTags } = createdTagsSlice.actions;

export default createdTagsSlice.reducer;