import React from "react";
import { Modal, Button } from "antd";
import ProgressDetails from "../templates/ProgressDetails";
import "./style.css";
import Title from "antd/es/typography/Title";

const OnboardingInProgressModal = ({
  open,
  onCancel,
  data,
  btnText = "Start 14 day trial",
}) => {
  return (
    <Modal
      centered
      title={
        <div
          style={{ color: "#050038", textAlign: "center", marginBottom: "6vh" }}
        >
          <Title
            level={2}
            style={{
              display: "inline-block",
              position: "relative",
              top: "-2px",
              marginRight: "10px",
            }}
          >
            Welcome to{" "}
          </Title>
          <img
            src={"/beena_logo_with_txt_vertical.png"}
            style={{ width: "100px" }}
          />
        </div>
      }
      styles={{
        footer: {
          marginTop: "10vh!important",
          textAlign: "center!important",
        },
        content: {
          padding: "10vh",
        },
      }}
      open={open}
      width={900}
      onCancel={onCancel}
      footer={
        <Button
          key="startTrial"
          onClick={onCancel}
          style={{ height: "50px", padding: "3vh", borderRadius: "0" }}
        >
          {btnText}
        </Button>
      }
      style={{
        padding: "5vh 5vh 10vh 5vh",
      }}
    >
      <ProgressDetails data={data} />
    </Modal>
  );
};

export default OnboardingInProgressModal;
