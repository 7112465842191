import React from "react";
import { Button } from "antd";

const OnboardingTemplateFooter = ({ onContinue, buttonText }) => {
  buttonText = buttonText || "Continue";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: "20px",
      }}
    >
      <Button
        type="primary"
        style={{ height: "50px", borderRadius: "4px" }}
        onClick={onContinue}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default OnboardingTemplateFooter;
