import React from "react";
import { Card, Row, Col } from "antd";
import CanvasJSReact from "@canvasjs/react-charts";
import DashboardTitle from "../../atoms/Title";
import DashboardText from "../../atoms/Text";
// import { Line } from "@ant-design/charts";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const OverviewChart = ({ data }) => {
  const options = {
    theme: "light2",
    animationEnabled: true,
    exportEnabled: true,
    axisY: {
      title: "Revenue ( in thousands )",
    },
    data: data,
  };

  return (
    <Row title="Overview">
      <Col span={24} style={{ marginBottom: "20px" }}>
        <DashboardTitle>Overview</DashboardTitle>
        <DashboardText style={{ marginBottom: "10px" }}>
          {`A breakdown of revenue earned between ${new Date(
            new Date().setMonth(new Date().getMonth() - 3)
          ).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })} - ${new Date().toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}`}
        </DashboardText>
      </Col>
      <Col span={24}>
        <Row>
          <Col
            span={6}
            style={{ display: "flex", flexDirection: "column-reverse" }}
          >
            <Row>
              {data.map((item, index) => (
                <Col span={24}>
                  <DashboardTitle level={3}>
                    $
                    {(item.dataPoints.reduce((acc, curr) => acc + curr.y, 0) *
                      1000).toLocaleString()}
                  </DashboardTitle>
                  <DashboardText>{item.name}</DashboardText>
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={18}>
            <CanvasJSChart options={options} />
          </Col>
        </Row>
      </Col>
      {/* <Line {...config} /> */}
    </Row>
  );
};

export default OverviewChart;
