import React from "react";
import { Card, Button, Typography } from "antd";

const { Title, Text } = Typography;

const BillingPlanCard = ({
  title,
  price,
  description,
  buttonText,
  onClick,
}) => {
  return (
    <Card
      style={{
        height: "100%",
        border: "1px solid rgba(0,0,0,0)",
        backgroundColor: "#F9F9F9",
        padding: "0 2.5vw",
        textAlign: "center",
      }}
      styles={{
        body: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          height: "100%",
        },
      }}
    >
      <Title level={4} style={{ textAlign: "center" }}>
        {title}
      </Title>
      <div style={{ marginBottom: "1rem" }}>{price}</div>
      <Text style={{ textAlign: "left" }}>{description}</Text>
      {buttonText && (
        <div style={{ marginTop: "auto" }}>
          <Button
            onClick={onClick}
            style={{ height: "50px", padding: "3vh", borderRadius: "0" }}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </Card>
  );
};

export default BillingPlanCard;
