import React from "react";
import { Row, Col, Typography } from "antd";

const { Title, Text } = Typography;

const Guide = ({ guideData, guideStyle }) => {
  return (
    <Row style={{ width: "100%", ...guideStyle }}>
      {guideData && (
        <Col
          span={guideData.guideSpan || 20}
          offset={(24 - (guideData.guideSpan || 20)) / 2}
        >
          {guideData.title && (
            <Title
              level={2}
              style={{ marginBottom: guideData.subtitle ? "2rem" : undefined }}
            >
              {guideData.title}
            </Title>
          )}
          {guideData.subtitle && (
            <Text style={{ fontSize: "1.25rem" }}>{guideData.subtitle}</Text>
          )}
          {guideData.guideSections.map((section, index) => (
            <div key={index} style={{ marginTop: "1.25rem" }}>
              <div key={index} style={{ marginBottom: "10px" }}>
                <Text style={{ lineHeight: "2", fontSize: "1.25rem" }}>
                  {guideData.showStepNumbers ? `${index + 1}. ` : ""}
                  {section.text}
                </Text>
              </div>
              {section.imgSrc && (
                <img
                  src={section.imgSrc}
                  alt="Guide Content"
                  style={{
                    maxWidth: "100%",
                    marginBottom: "10px",
                    maxHeight: "250px",
                  }}
                />
              )}
            </div>
          ))}
        </Col>
      )}
    </Row>
  );
};

export default Guide;
