import React from "react";
import { Card } from "antd";
import TagItem from "../../molecules/Dashbaord/TagItem";
import { Typography } from "antd";
import DashboardText from "../../atoms/Text";
import { Row, Col } from "antd";
import "../style.css";
import DashboardTitle from "../../atoms/Title";

const { Title } = Typography;

const TagsList = ({ tags }) => {
  return (
    <Card
      className="tags-list"
      style={{ border: "none", backgroundColor: "#F9F9F9" }}
    >
      <Row>
        <Col span={16}>
          <DashboardTitle level={4}>Tags</DashboardTitle>
        </Col>
        <Col span={8}>
          <DashboardTitle level={4}># of Customers</DashboardTitle>
        </Col>
      </Row>
      {tags.map((tag, index) => (
        <TagItem key={index} tag={tag.name} count={tag.count} />
      ))}
    </Card>
  );
};

export default TagsList;
