import React from "react";
import { Tag as AntTag } from "antd";
import styled from "styled-components";
import { CheckOutlined } from "@ant-design/icons";

const StyledTag = styled(AntTag.CheckableTag)`
  font-size: 16px;
  border-radius: 25px;
  display: flex;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  flex-direction: column;
  border: 1px solid #000 !important;
  background-color: white;
  position: relative;
`;

const CheckmarkContainer = styled.div`
  position: absolute;
  top: -5px;
  left: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid green;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Checkmark = styled(CheckOutlined)`
  font-size: 12px;
  color: green;
`;

const CustomerTag = ({ children, created = false, ...props }) => {
  return (
    <StyledTag {...props}>
      {created && (
        <CheckmarkContainer title="Tag already created">
          <Checkmark />
        </CheckmarkContainer>
      )}
      {children}
    </StyledTag>
  );
};

export default CustomerTag;
