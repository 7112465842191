import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

const DashboardTitle = ({ level, children, align }) => {
  return <Title level={level} align={align}>{children}</Title>;
};

export default DashboardTitle;
