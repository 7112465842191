import React from "react";
import { Col, Layout, Menu, Row, theme, Image } from "antd";
import { useSelector } from "react-redux";
import {
  SettingFilled,
  UserOutlined,
  UnorderedListOutlined,
  ProductOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./style.css";

const { Content, Sider } = Layout;

const LoggedInPagesTemplate = ({ contentStyle, children }) => {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const defaultSelectedKeys = currentPath.split("/").slice(1);

  return (
    <Layout hasSider={true} style={{ height: "100vh" }}>
      <Sider
        style={{
          overflow: "hidden",
          height: "100vh",
          // width: "50vw",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "#F5F5F5",
          paddingTop: "1.5rem",
        }}
        width={350}
      >
        <Row
          style={{ height: "100%", flexDirection: "column", margin: "1rem" }}
        >
          <Col span={3} style={{ width: "100%", maxWidth: "100%" }}>
            <a
              href="/"
              style={{
                display: "block",
                width: "100%",
                height: "100%",
                backgroundImage: "url(/beena_logo_with_text.png)",
                backgroundSize: "40%",
                backgroundPosition: "left",
                marginLeft: "20px",
                backgroundRepeat: "no-repeat",
              }}
            ></a>
          </Col>
          <Col
            span={13}
            style={{
              width: "100%",
              maxWidth: "100%",
              backgroundColor: "#F5F5F5",
            }}
          >
            <Menu
              mode="inline"
              defaultOpenKeys={["use-cases"]}
              selectedKeys={defaultSelectedKeys}
              items={[
                {
                  key: "dashboard",
                  icon: <ProductOutlined />,
                  label: "Dashboard",
                  onClick: () => {
                    navigate("/dashboard");
                  },
                },
                {
                  key: "use-cases",
                  icon: <UnorderedListOutlined />,
                  label: "Use Cases",
                  children: [
                    {
                      key: "segments",
                      label: "Segments",
                      onClick: () => {
                        navigate("/use-cases/segments");
                      },
                    },
                    {
                      key: "abandoned-cart",
                      label: "Abandoned Cart",
                      onClick: () => {
                        navigate("/use-cases/abandoned-cart");
                      },
                    },
                    {
                      key: "replenishment",
                      label: "Replenishment",
                      onClick: () => {
                        navigate("/use-cases/replenishment");
                      },
                    },
                    {
                      key: "churn",
                      label: "Churn",
                      onClick: () => {
                        navigate("/use-cases/churn");
                      },
                    },
                  ],
                },
              ]}
              style={{
                height: "100%",
                borderRight: 0,
                backgroundColor: "#F5F5F5",
              }}
              // theme="dark"
            />
          </Col>
          <Col span={8} style={{ width: "100%", maxWidth: "100%" }}>
            <Menu
              mode="inline"
              selectedKeys={defaultSelectedKeys}
              defaultOpenKeys={["settings"]}
              items={[
                {
                  key: "settings",
                  icon: <SettingOutlined />,
                  label: "Settings",
                  children: [
                    {
                      key: "billing",
                      label: "Billing",
                      onClick: () => {
                        navigate("/settings/billing");
                      },
                    },
                    {
                      key: "tags",
                      label: "Tags",
                      onClick: () => {
                        navigate("/settings/tags");
                      },
                      // disabled: true,
                    },
                  ],
                },
                {
                  key: "logout",
                  icon: <LogoutOutlined />,
                  label: "Logout",
                  onClick: () => {
                    localStorage.clear();
                    navigate("/login");
                  },
                },
              ]}
              style={{
                height: "100%",
                borderRight: 0,
                width: "100%",
                backgroundColor: "#F5F5F5",
              }}
              //   theme="dark"
            />
          </Col>
        </Row>
      </Sider>
      <Content
        style={{
          margin: "0px 0px 0 350px",
          padding: "4vh 50px",
          overflow: "initial",
          height: "100%",
          backgroundColor: "#fff",
          // width: "calc(100vw - 500px)",
          ...contentStyle,
        }}
      >
        <Row style={{ padding: "1rem" }}>
          <Col span={24}>{children}</Col>
        </Row>
      </Content>
    </Layout>
  );
};
export default LoggedInPagesTemplate;
