import React from "react";
import { useSelector } from "react-redux";

const CreatedTags = () => {
  const createdTags = useSelector((state) => state.createdTags?.tags || []);
  // console.log(createdTags);

  return (
    <>
      {createdTags.length > 0 && (
        <div
          style={{
            position: "absolute",
            padding: "3vh 4vw 2.5vh 4vw",
            top: "0",
            left: "0",
            backgroundColor: "#FFFFFF",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
            <span style={{ fontSize: "12px", alignSelf: "center" }}>
              <b>Tags created so far:</b>
            </span>
            {createdTags.map((tag, index) => (
              <span
                key={index}
                style={{
                  padding: "3px 7px",
                  borderRadius: "15px",
                  background: "#FFF0F0",
                  border: "1px solid #95928E",
                }}
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CreatedTags;
