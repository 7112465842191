import React from "react";
import { Card, Col, Row } from "antd";
import DashboardTitle from "../../atoms/Title";
import DashboardText from "../../atoms/Text";

const RevenueCard = ({ revenue, roi }) => {
  return (
    <Card style={{ border: "15px solid #F9F9F9" }}>
      <Row>
        <Col span={12}>
          <DashboardTitle level={2}>${revenue}</DashboardTitle>
          <DashboardText>Lifetime Revenue</DashboardText>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <DashboardTitle level={3} align="right">{roi}x</DashboardTitle>
          <DashboardText align="right">ROI</DashboardText>
        </Col>
      </Row>
    </Card>
  );
};

export default RevenueCard;
